<template>
    <div id="content" class="issue-state-management">
        <div class="bar-actions">
            <TranslateButton :entityId="'issue_state.' + issueStateRender.id" :translationLangs="issueStateRender.translations" @newTranslation="newTranslation" @deleteTranslation="deleteTranslation"></TranslateButton>
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveIssueState" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showDeleteIssueStateModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="issueStateRender">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('user.user_name') }}</h2>
                        <div class="user-answer">
                            <TranslateInput :translation_keyname="'issue_state.' + issueStateRender.id + '.name'" @input="updateValues($event)" v-model="issueStateRender.name" :gOptions="{ mandatory: true }" :_placeholder="$t('issueState.placeholder_name')" :gMessage="{ error: $t('product.product_state.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></TranslateInput>
                            <!-- <Input @input="updateValues($event)" v-model="issueStateRender.name" :gOptions="{ mandatory: true }" :gPlaceholder="$t('issueState.placeholder_name')" :gLabel="$t('user.user_name')" :gMessage="{ error: $t('product.product_state.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></Input> -->
                        </div>
                    </div>
                    <div class="right-container translate-disable">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="issueStateRender.status ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()" :class="{ disabled: issueStateRender.type == 3 || issueStateRender.type == 1 }">
                                <input class="switch-input-form" type="checkbox" v-model="issueStateRender.status" disabled="true" />
                            </div>
                        </div>
                        <p class="description" v-html="$t('issueState.switch_description')"></p>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container translate-disable">
                        <h2 class="label-form">{{ $t('product.product_category.color') }}</h2>
                        <p class="description" v-html="$t('issue_state.color_description')"></p>
                        <p class="error-form error-field" v-if="errors.color">{{ $t('issue_state.error_color') }}</p>
                        <div class="user-answer ball-colours">
                            <span class="ball" @click="setColor(col)" v-for="col in colors" :style="{ backgroundColor: col }" :class="{ active: issueStateRender.color == col }" :key="col"></span>
                        </div>
                    </div>
                    <div class="right-container"></div>
                </div>
                <div class="label-container" v-if="issueStateRender.type == 1">
                    <div class="left-container translate-disable" :class="{ disabled: true }">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="$t('issueState.default_value')"></h2>
                            <div class="switch-click" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="issueDefault" disabled="true" />
                            </div>
                        </div>
                        <p class="description" v-html="$t('issueState.value_default')"></p>
                    </div>
                    <div class="right-container"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'IssueStatesForm',
    props: { id: undefined },
    components: {},
    data() {
        return {
            sendForm: true,
            errors: {
                name: false,
                color: false
            },
            idIssueState: false,
            form: false,
            colors: extraColors,
            moreOptions: false,
            issueDefault: false
        }
    },
    computed: {
        issueStateRender: {
            get() {
                if (this.idIssueState) {
                    let issueState = this.$store.getters['issueStates/getIssueStates'](this.idIssueState)
                    if (issueState.status === -2) {
                        issueState.status = 0
                    }
                    return issueState
                }
            },
            set(value) {
                this.$store.commit('issueStates/updateIssueState', value)
            }
        }
    },
    methods: {
        newTranslation(code) {
            this.$set(this.issueStateRender.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.issueStateRender.translations, code)
        },

        // Method for make changes from form to store object
        updateValues(v) {
            // this.issueStateRender.name = v
            this.issueStateRender = this.issueStateRender
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },
        setColor(color) {
            this.issueStateRender.color = color
            this.updateValues(color)
        },

        popupStatus() {
            var self = this
            if (self.issueStateRender.type == 2 || self.$route.name == 'AddIssueStates') {
                if (self.issueStateRender.status == '1' || self.issueStateRender.status == true) {
                    self.$popup.confirm({
                        message: self.$t('issueState.status_inactive'),
                        textSave: self.$t('product.product_state.state_save'),
                        callSave: function () {
                            self.issueStateRender.status = 0
                            self.updateValues(self.issueStateRender.status)
                        },
                        callCancel: function () {
                            self.issueStateRender.status = 1
                            self.updateValues(self.issueStateRender.status)
                        }
                    })
                } else {
                    self.issueStateRender.status = 1
                    self.updateValues(self.issueStateRender.status)
                }
            }
        },

        load() {
            var self = this
            // Cargar categorías cuando se hace refresh en el formulario
            if (Object.values(self.$store.getters['issueStates/getIssueStates']()).length == 0) {
                self.$store.dispatch('issueStates/loadIssueStates', {}).then(() => {
                    if (self.$route.name == 'IssueStates') {
                        if (typeof self.id != 'undefined') {
                            self.idIssueState = self.id
                            self.$router.push({
                                name: 'EditIssueStates',
                                params: { id: self.id }
                            })
                        } else {
                            self.$store.dispatch('issueStates/addNewIssueState').then((id) => {
                                self.idIssueState = id
                                self.$router.push({ name: 'AddIssueStates' })
                            })
                        }
                    } else {
                        if (typeof self.id != 'undefined') {
                            self.idIssueState = self.id
                        } else {
                            self.$store.dispatch('issueStates/addNewIssueState').then((id) => {
                                self.idIssueState = id
                            })
                        }
                    }
                })
            } else {
                if (self.$route.name == 'IssueStates') {
                    if (typeof self.id != 'undefined') {
                        self.$router.push({
                            name: 'EditIssueStates',
                            params: { id: self.id }
                        })
                        self.idIssueState = self.id
                    } else {
                        self.$store.dispatch('issueStates/addNewIssueState').then((id) => {
                            self.idIssueState = id
                            self.$router.push({ name: 'AddIssueStates' })
                        })
                    }
                }
            }
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveIssueState() {
            var self = this
            self.$overlay.loading()
            this.$store.dispatch('issueStates/validateIssueStates', this.idIssueState).then((response) => {
                if (response.status) {
                    var sendForm = self.$route.params.id ? 'editIssueState' : 'addIssueState'
                    self.$store.dispatch('issueStates/' + sendForm, self.issueStateRender).then((response) => {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$route.params.id ? self.$t('snackbar.edit_success') + ' <b>' + self.issueStateRender.name + '</b>' : self.$t('snackbar.add_success') + ' <b>' + self.issueStateRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    self.errors = response.errors
                    self.$overlay.hide()
                    scrollError()
                }
            })
        },
        deleteIssueState() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.issueStateRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('issueStates/removeIssueState', self.issueStateRender.id).then(() => {
                self.$emit('back')
                self.$snackbar.open(snackbarData)
            })
        },

        showDeleteIssueStateModal() {
            var self = this
            const modalMessage = self.$t('issue_state.delete', { name: self.issueStateRender.name })

            this.$popup.delete({ message: modalMessage, callSave: this.deleteIssueState })
        }
    },

    created() {
        this.load()
    },
    mounted() {},
    updated() {}
}
</script>

<style lang="scss">
#content.issue-state-management {
    .left-container {
        padding-left: 0;
    }
    .disabled {
        cursor: not-allowed;
    }
}
</style>
