<template>
    <div id="content" class="issueStates">
        <template v-if="form">
            <Form :id="issueStateID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="addIssueState" bLabel="product.product_state.addButton"></Button>
            </div>

            <div class="issueStates-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }"
                >
                    <FilterCategory
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length == 0"
                        @resetPageDatatable="top(true)"
                    ></FilterCategory>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="Object.values(items)"
                        sort-by="name"
                        :sort-asc="true"
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                    >
                        <template #item.name="item">
                            <div class="name" @click="goToReport(item.item.id)">
                                <div class="ball" :style="[item.item.color ? { backgroundColor: item.item.color } : { backgroundColor: '#B6B9BE' }]"></div>
                                <span class="issueName">{{ item.item.name }}</span>
                            </div>
                        </template>
                        <template #item.type="item">
                            <div @click="goToReport(item.item.id)">
                                <div class="type" v-if="item.item.type == 3">
                                    {{ $t('issue_state.closed') }}
                                    <div class="tooltip" v-tooltip="{ content: $t('issue_state.info_tooltip_closed') }"></div>
                                </div>
                                <div class="type" v-else-if="item.item.type == 1">
                                    {{ $t('issueState.typology.default') }}
                                    <div class="tooltip" v-tooltip="{ content: $t('issue_state.info_tooltip_default') }"></div>
                                </div>
                            </div>
                        </template>
                        <template #item.translations="item" v-if="organizationRender.availableLangs">
                            <div @click="goToReport(item.item.id)">
                                <template v-if="!getTranslations(item.item.translations)">
                                    <!-- {{ $t('multi-language.no_translated') }} -->
                                </template>
                                <template v-else>
                                    <Tags :tags="getTranslations(item.item.translations)" :nameTag="'name'" value="name" :backColor="'backColor'"></Tags>
                                </template>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <TableStatuses
                                    :text="item.item.status == '1' ? $t('sites.form.active') : item.item.status == '-2' ? $t('sites.form.inactive') : ''"
                                    :status="item.item.status == '1' ? 'active' : 'inactive'"
                                >
                                </TableStatuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && Object.values(items).length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="addIssueState"
                            :buttonText="$t('product.product_category.addButton')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.products_categories')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '@/components/ui/TableStatuses.vue'
import i18n from '@/i18n'
import FilterCategory from '@/components/domain/issueStates/filter'
import Form from '@/components/domain/issueStates/form'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilterCategory,
        Form,
        EmptyTable,
        TableStatuses
    },
    name: 'IssueStates',
    data() {
        return {
            noChanges: false,
            issueStateID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('product.product_category.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    class: 'header-table'
                },
                {
                    text: this.$t('issueState.tipology'),
                    value: 'type',
                    align: 'start',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.product_state.status'),
                    value: 'status',
                    align: 'start',
                    width: '150px',
                    //   sortable: true,
                    //   sort: this.sortColumnGroup,
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            const numItems = this.$store.getters['issueStates/getNumItems']
            return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        },
        numItems() {
            return this.$store.getters['issueStates/getNumItems']
        },
        items() {
            var result = this.$store.getters['issueStates/getIssueStates']()
            const arrayOfResults = Object.values(result)
            const onlyActiveAndInactive = arrayOfResults.filter((item) => {
                return item.status == '1' || item.status == '-2'
            })
            return onlyActiveAndInactive
        },
        hasAccess() {
            return this.checkPermission('/issue_states/add')
        },
        itemsLoaded() {
            return this.$store.getters['issueStates/getItemsLoaded']
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        organizationRender() {
            var org = this.$store.getters['account/getOrganization']

            if (org && org.account && org.languages) {
                var account = org.account
                // var languages = org.languages
                var availableTranslationLanguages = account.config.languages.split(',') != '' ? account.config.languages.split(',') : false

                var result = {
                    allLangs: org.languages,
                    accountLanguage: org.languages[account.lang],
                    availableLangs: availableTranslationLanguages
                }
                return result
            }
            return false
        }
    },
    methods: {
        getTranslations(oTranslations) {
            var self = this
            if (oTranslations && Object.keys(oTranslations).length > 0) {
                return Object.keys(oTranslations).map(function (key) {
                    if (self.organizationRender.availableLangs.includes(key)) {
                        return {
                            name:
                                self.organizationRender.allLangs[key].name +
                                ' <b>(' +
                                parseInt((oTranslations[key].translated / (oTranslations[key].missing + oTranslations[key].translated)) * 100) +
                                '%)</b>',
                            backColor: oTranslations[key].missing > 0 ? '#f5d9bc' : '#c2f3db'
                        }
                    }
                })
            } else return false
        },

        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        goToReport(id) {
            if (this.checkPermission('/issue_states/idProd')) {
                this.form = true
                this.issueStateID = id
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('issue_state.no_permission'),
                    duration: 5000
                })
            }
        },
        addIssueState() {
            if (this.checkPermission('/issue_states/add')) {
                this.form = true
                this.issueStateID = undefined
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('issue_state.no_permission'),
                    duration: 5000
                })
            }
        },
        closeForm() {
            this.form = false
            this.issueStateID = false
            this.$router.push({
                name: 'IssueStates',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        load() {
            if (this.$route.params.id) {
                this.goToReport(this.$route.params.id)
            } else if (this.$route.name == 'AddIssueStates') {
                this.addIssueState()
            } else {
                this.form = false
                this.issueStateID = false
            }

            var self = this
            var items = this.$store.getters['issueStates/getIssueStates']()
            var filters = this.$store.getters['issueStates/getFiltersStatus']()

            // HEADERS OF MULTI-LANGUAGE
            var translationHeader = {
                text: this.$t('multi-language.translations'),
                value: 'translations',
                align: 'start',
                width: '300px',
                class: 'header-table'
            }
            var hasTranslation = self.headers.filter((header) => {
                return header.value == 'translations'
            })

            if (!this.organizationRender) {
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    if (self.organizationRender.availableLangs.length > 0 && hasTranslation == 0) {
                        self.headers.splice(2, 0, translationHeader)
                    }
                })
            } else {
                if (self.organizationRender && self.organizationRender.availableLangs.length > 0 && hasTranslation.length == 0) {
                    self.headers.splice(2, 0, translationHeader)
                }
            }

            if (this.$route.name != 'AddIssueStates') {
                if (Object.values(items).length > 0 && Object.values(filters).length > 0) {
                    // delect this line for ANDY-602
                    // self.$store.dispatch('issueStates/loadIssueStates', { status: '1, -2' }).then(function () {})
                } else {
                    self.$store.dispatch('issueStates/loadFiltersIssueStates', {}).then(function () {
                        self.$store.dispatch('issueStates/loadIssueStates', { status: '1' }).then(function () {})
                    })
                }
            }
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('issueStatesFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('issueStatesFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    watch: {
        $route(to, from) {
            this.load()
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.issueStates {
    .issueStates-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }
                .name {
                    @include display-flex();
                    @include align-items(center);
                    min-height: 20px;

                    .ball {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        margin-right: 6px;
                    }
                    .issueName {
                        width: calc(100% - 21px);
                    }
                    .ball-color {
                        display: inline-block;
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        margin-right: 3px;
                    }
                }
                .type {
                    @include display-flex();
                    .tooltip {
                        margin-left: 6px;
                        width: 17px;
                        height: 17px;
                        @include background($image: img('info_tooltip.svg'));
                    }
                }
                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
